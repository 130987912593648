<template>
  <div>
    <b-alert
      show
      variant="info"
    >
      <div class="alert-body">
        <feather-icon
          icon="InfoIcon"
          class="mr-50 mt-25"
        />
        {{ $t('viewSettings.connectionStringsConditionFilters') }}
      </div>
    </b-alert>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Lists.Show') }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('Lists.Entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('Lists.Search')"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            offset-md="2"
          >
            <b-button
              variant="primary"
              @click="addNewConditionFilter"
            >
              <span class="text-nowrap">{{ $t('Lists.Add') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refConditionFiltersTable"
        :sort-by.sync="sortBy"
        class="position-relative"
        :items="refetchConditionFilters"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('Lists.NoMatchingRecordsFound')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(objetoConsulta)="data">
          {{ data.item.condicionTipoId == 4 ? data.item.objetoConsulta.nombre : 'N/A' }}
        </template>
        <!-- Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- Edit  -->
            <b-dropdown-item
              :to="{ name: 'settings-dataaccess-conditionfilters-edit', params: { ConditionId: data.item.condicionFiltradoId } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Lists.Edit') }}</span>
            </b-dropdown-item>
            <!-- Delete -->
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span
                class="align-middle ml-50"
                @click="showDeleteConfirmation(data.item.condicionFiltradoId) "
              >{{ $t('Lists.Delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Lists.Showing') }} {{ dataMeta.Showing }} {{ $t('Lists.To') }} {{ dataMeta.to }} {{ $t('Lists.Of') }} {{ dataMeta.of }} {{ $t('Lists.Entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BAlert,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import { ref, watch, computed } from '@vue/composition-api'
// import BaseListEdit from '@/views/components/base-list-edit/BaseListEdit.vue'
import generalDataService from '@/services/generalData.service'
import vSelect from 'vue-select'
import i18n from '@/libs/i18n'
import router from '@/router'

export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    // BaseListEdit,
  },
  setup() {
    /* Refs */
    const refConditionFiltersTable = ref(null)
    /* Services */
    const { fetchConditionFilters } = generalDataService()
    /* Pagination */
    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(10)
    const currentPage = ref(1)
    /* Params */
    const status = ref('')
    const searchQuery = ref(null)
    const isSortDirDesc = ref(false)
    const sortBy = ref('condicionFiltradoId')
    /* Data */
    const totalRecords = ref(0)
    const records = ref([])
    const typeCondition = ref([])
    const tableColumns = [
      {
        label: i18n.t('DataAccess.ConditionFilters.columns.typeCondition'),
        key: 'condicionTipo.nombre',
      },
      {
        label: i18n.t('DataAccess.ConditionFilters.columns.columnDataType'),
        key: 'columnaTipoDato.nombre',
      },
      {
        label: i18n.t('DataAccess.ConditionFilters.columns.logicalOperatorType'),
        key: 'operadorLogicoTipo.nombre',
      },
      {
        label: i18n.t('DataAccess.ConditionFilters.columns.tag'),
        key: 'etiqueta',
      },
      {
        label: i18n.t('DataAccess.ConditionFilters.columns.queryObject'),
        key: 'objetoConsulta',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const refetchConditionFilters = (ctx, callback) => {
      fetchConditionFilters({
        searchQuery: searchQuery.value,
        limit: perPage.value,
        offSet: currentPage.value === 1 ? 0 : (currentPage.value * perPage.value) - perPage.value,
        sort: `${sortBy.value} ${isSortDirDesc.value ? 'ASC' : 'DESC'}`,
        estado: status.value,
      }, data => {
        records.value = data.condicionesFiltrado
        totalRecords.value = data.total
        callback(records.value)
      })
    }
    /* UI */
    const dataMeta = computed(() => {
      const localItemsCount = refConditionFiltersTable.value ? refConditionFiltersTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalRecords.value,
      }
    })
    const addNewConditionFilter = () => {
      router.push({ name: 'settings-dataaccess-conditionfilters-add' })
    }
    const refreshData = () => {
      refConditionFiltersTable.value.refresh()
    }
    watch([currentPage, perPage, status, searchQuery], () => {
      refreshData()
    })

    return {
      // Params
      searchQuery,
      sortBy,
      refetchConditionFilters,
      isSortDirDesc,
      // Ref
      refConditionFiltersTable,
      // Data
      tableColumns,
      records,
      totalRecords,
      typeCondition,
      // Pagination
      status,
      perPageOptions,
      perPage,
      currentPage,
      /* UI */
      dataMeta,
      // Event
      refreshData,
      addNewConditionFilter,
    }
  },
  methods: {
    showDeleteConfirmation(ConditionId) {
      let ConditionFilter = ref([])
      const { deleteConditionFilter } = generalDataService()
      this.$bvModal
        .msgBoxConfirm('¿Seguro de eliminar Condicion?', {
          title: i18n.t('Lists.pleaseConfirm'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: i18n.t('Lists.Yes'),
          cancelTitle: i18n.t('Lists.No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        }).then(deleteConfirmation => {
          if (deleteConfirmation) {
            ConditionFilter = ConditionId
            deleteConditionFilter(ConditionFilter)
            this.$router.go(0)
          }
        })
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
